import React from "react";
import { toAbsoluteUrl } from "../../helpers/assetHelper";

function OurProjectHeader({ projectImage1, projectImage2, projectImage3 }) {
  return (
    <>
      {/* for desktop */}
      <div className="hidden lg:flex flex-row justify-center items-center">
        <img
          src={toAbsoluteUrl(projectImage1)}
          alt="project_image_1"
          className="w-[320px] h-[225px] rounded-tl-md mr-[-25px] border-2 object-fill"
        />
        <img
          src={toAbsoluteUrl(projectImage2)}
          alt="project_image_2"
          className="w-[480px] h-[340px] rounded-md drop-shadow shadow object-fill"
        />
        <img
          src={toAbsoluteUrl(projectImage3)}
          alt="project_image_3"
          className="w-[320px] h-[225px] rounded-tr-md ml-[-25px] border-2 object-fill"
        />
      </div>

      {/* for small devices */}
      <div className="lg:hidden flex flex-row justify-center items-center mx-2">
        <img
          src={toAbsoluteUrl(projectImage2)}
          alt="project_image_2"
          className="w-[480px] h-[340px] rounded-md drop-shadow shadow object-fill"
        />
      </div>
    </>
  );
}

export default OurProjectHeader;
