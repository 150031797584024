import React from "react";
import KTSVG from "../utils/KTSVG";

function CompanyNumberCard({ className, text, number, icon }) {
  return (
    <div className={`${className} flex flex-row justify-start items-center`}>
      <KTSVG
        fill="white"
        svgClassName="w-[90px] h-[90px] p-4"
        className="bg-gray-300 bg-opacity-20"
        path={icon}
      />
      <div className="flex flex-col text-white justify-start ml-3 ">
        <p className="text-xs">{text}</p>

        <p className="text-3xl font-semibold">{number}</p>
      </div>
    </div>
  );
}

export default CompanyNumberCard;
