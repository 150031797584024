import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";

function MarketingSectionCard({ title, description, className }) {
  return (
    <div
      className={`${className} flex flex-col justify-center items-center md:text-start md:justify-start md:items-start 
    text-center pr-1 md:pr-4 lg:pr-16 py-3`}
    >
      <h3 className="flex items-center  text-sm md:text-lg xl:text-xl font-bold mb-2 h-10">
        <IoCheckmarkCircle className="text-primary-light-color mr-1" />
        {title}
      </h3>
      <p className="text-[18px] font-normal ">{description}</p>
    </div>
  );
}

export default MarketingSectionCard;
