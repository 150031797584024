import React from "react";
import { companyNumbersConfig } from "../configs/companyNumbersConfig";
import CompanyNumberCard from "./CompanyNumberCard";
import { useTranslation } from "react-i18next";

function CompanyNumbers({
  backgroundImage,
  height,
  wrapperClassName,
  contentClassName,
}) {
  const { t: translate } = useTranslation();

  const getBackgroundColor = () => {
    if (backgroundImage) {
      return backgroundImage;
    }
    return "bg-gradient-to-r from-cyan-500 to-blue-500";
  };

  return (
    <div
      className={`${getBackgroundColor()} cursor-default bg-center bg-cover bg-no-repeat duration-500
      bg-gradient-to-tr from-gray-600 to-gray-600 w-full m-auto
      flex justify-center items-center 
      z-10 relative
      before:content-['']
      before:absolute
      before:inset-0
      before:block
      before:bg-gradient-to-tr
      before:from-gray-900
      before:to-gray-700
      before:opacity-90
      before:z-[-5]
      ${wrapperClassName}
      `}
      style={{
        backgroundImage: `${backgroundImage ? `url(${backgroundImage})` : ""}`,
        minHeight: height,
      }}
    >
      <div
        className={`max-w-7xl mx-auto flex flex-row flex-wrap justify-center items-center select-none  ${contentClassName}`}
      >
        {companyNumbersConfig.map((companyNumber, idx) => {
          return (
            <CompanyNumberCard
              className={
                "min-w-[220px] md:basis-1/3 sm:basis-1/2 basis-1/2 my-3 px-2"
              }
              idx={idx}
              key={companyNumber.id}
              icon={companyNumber.icon}
              text={translate(companyNumber.text)}
              number={companyNumber.number}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CompanyNumbers;
