import React from "react";
import KTSVG from "../../utils/KTSVG";
import { projectsTechnologies } from "../../configs/ourProjectsConfig";
import OurProjectHeader from "./OurProjectHeader";
import { useTranslation } from "react-i18next";

function OurProjectsComponent({
  projectName,
  projectDescription,
  projectImage1,
  projectImage2,
  projectImage3,
  projectTechnologies,
  sections,
}) {
  const { t: translate } = useTranslation();

  const activeTechnologies = projectsTechnologies.filter((technology) => {
    return projectTechnologies.includes(technology.value);
  });

  return (
    <div className="py-[120px]">
      <OurProjectHeader
        projectImage1={projectImage1}
        projectImage2={projectImage2}
        projectImage3={projectImage3}
      />

      <div className="mt-[-57px]">
        <div className="bg-gray-900 min-h-[700px]  rounded-3xl flex flex-col justify-center py-[60px]">
          <div className="max-w-5xl mx-auto flex flex-col justify-center items-center text-white mt-[57px]">
            <h1 className="text-4xl font-bold text-hero-hover text-center">
              {projectName}
            </h1>
            <p className="text-center text-xl pt-5 px-2  font-medium">
              {projectDescription}
            </p>
          </div>

          <div className="flex flex-col md:flex-row flex-wrap items-start justify-evenly text-center mx-auto ">
            {sections}
          </div>

          <div className="flex flex-col justify-center items-center ">
            <h1 className="text-2xl font-bold text-white">
              {translate("technologies_used")}
            </h1>

            <div className="flex flex-row flex-wrap justify-center py-[10px]  mt-3">
              {activeTechnologies.map((activeTechnology) => {
                return (
                  <div
                    key={activeTechnology.value}
                    className="flex flex-col justify-center items-center mx-3 mb-2"
                  >
                    <KTSVG
                      path={activeTechnology.icon}
                      svgClassName="w-[55px] h-[55px] mb-3 p-2 border border-gray-800 rounded-xl bg-gray-800"
                      color="white"
                    />
                    <p className="text-white font-semibold">
                      {activeTechnology.label}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurProjectsComponent;
