import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function HeroComponent({
  title,
  description,
  buttonText,
  backgroundImage,
  height,
  wrapperClassName,
  contentClassName,
  buttonVariant,
  url,
}) {
  const navigate = useNavigate();

  const getBackgroundColor = () => {
    if (backgroundImage) {
      return backgroundImage;
    }
    return "bg-gradient-to-r from-cyan-500 to-blue-500";
  };

  const handleGoToPage = () => {
    navigate(url);
  };

  return (
    <div
      className={`${getBackgroundColor()} cursor-default bg-center bg-cover bg-no-repeat duration-500 
      bg-gradient-to-tr from-gray-600 to-gray-600 w-full m-auto
      flex justify-center items-center 
      z-10 relative
      before:content-['']
      before:absolute
      before:inset-0
      before:block
      before:bg-gradient-to-tr
      before:from-gray-800
      before:to-gray-800
      before:opacity-70
      before:z-[-5]
      ${wrapperClassName}
      `}
      style={{
        backgroundImage: `${backgroundImage ? `url(${backgroundImage})` : ""}`,
        height: height,
      }}
    >
      <div
        className={`flex flex-col justify-center items-center select-none text-white p-10 ${contentClassName}`}
      >
        <h1 className="max-w-5xl mx-auto text-4xl md:text-5xl lg:text-6xl drop-shadow mb-5 font-bold text-center">
          {title}
        </h1>
        {description && (
          <p className="max-w-5xl mx-auto text-center text-white text-sm lg:text-base drop-shadow font-medium mb-10 md:px-16">
            {description}
          </p>
        )}
        {buttonText && (
          <Button
            onClick={handleGoToPage}
            variant={`${buttonVariant === "secondary" ? "secondary" : "hero"}`}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default HeroComponent;
