import React from "react";

function Mailto({
  email,
  subject = "",
  body = "",
  children,
  disable,
  className,
}) {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  if (disable) {
    return (
      <div
        className={`${className} btn bg-green-100 text-gray-300 !cursor-default`}
      >
        {children}
      </div>
    );
  }

  return (
    <a
      className={`${className} btn btn-primary`}
      href={`mailto:${email}${params}`}
    >
      {children}
    </a>
  );
}

export default Mailto;
