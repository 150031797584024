import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import eventsReducer from "../reducer/events.reducer";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {
    events: eventsReducer,
  },
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => {
    if (isDebuggerMode) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});
