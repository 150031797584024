import React from "react";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { marketingConfig } from "../configs/marketingConfig";
import SliderAndContentComponent from "../components/PagesComponents/SliderAndContentComponent";
import ImageAndContentComponent from "../components/PagesComponents/ImageAndContentComponent";

function Marketing() {
  const { t: translate } = useTranslation();

  return (
    <div className="min-h-screen">
      <HeroComponent
        height={650}
        title={translate("hero_marketing_title")}
        backgroundImage={toAbsoluteUrl("/assets/SSNTAssets/project-pic.jpg")}
        wrapperClassName="bg-center"
      />

      <h2 className="text-primary-color text-3xl md:text-3xl lg:text-4xl drop-shadow  font-bold text-center py-[60px]">
        {translate("marketing_offered_services")}
      </h2>

      <div className="max-w-7xl mx-auto">
        {marketingConfig.map((item, idx) => {
          const images = item.images;
          if (idx === 0) {
            return (
              <SliderAndContentComponent
                key={`marketing-${idx}`}
                title={translate(item.title)}
                descriptions={item.descriptions.map((description, idx) => {
                  return (
                    <p
                      key={`description-${idx}`}
                      className={"text-sm xl:text-base font-medium  mb-6"}
                    >
                      {translate(description)}
                    </p>
                  );
                })}
                sliderData={images}
              />
            );
          }

          return (
            <ImageAndContentComponent
              type={`${idx % 2 ? "reverse" : ""}`}
              key={`marketing-${idx}`}
              title={translate(item.title)}
              descriptions={item.descriptions.map((description, idx) => {
                return (
                  <p
                    key={`description-${idx}`}
                    className={"text-sm xl:text-base font-medium  mb-6"}
                  >
                    {translate(description)}
                  </p>
                );
              })}
              sectionImage={item.image}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Marketing;
