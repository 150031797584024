import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import KTSVG from "../../utils/KTSVG";

function CustomHero({
  title,
  subtitle,
  description,
  buttonText,
  backgroundImage,
  height,
  wrapperClassName,
  contentClassName,
  buttonVariant,
  url,
  iconPath,
  iconClassName,
}) {
  const navigate = useNavigate();

  const getBackgroundColor = () => {
    if (backgroundImage) {
      return backgroundImage;
    }
    return "bg-gradient-to-r from-cyan-500 to-blue-500";
  };

  const handleGoToPage = () => {
    navigate(url);
  };

  return (
    <div
      className={`${getBackgroundColor()} cursor-default bg-center bg-cover bg-no-repeat duration-500
      bg-gradient-to-tr from-gray-600 to-gray-600 w-full m-auto flex flex-row justify-between items-center 
      ${wrapperClassName}
      `}
      style={{
        backgroundImage: `${backgroundImage ? `url(${backgroundImage})` : ""}`,
        height: height,
      }}
    >
      <div
        className={`max-w-7xl mx-auto flex flex-row justify-center items-center text-black ${contentClassName}`}
      >
        <div className="">
          <span className="block max-w-[150px] md:max-w-[250px] h-[15px] bg-primary-light-color ml-[220px] md:ml-[340px] lg:ml-[490px]" />

          <h1
            className={` text-3xl md:text-5xl lg:text-6xl drop-shadow font-bold mt-2 ${
              !subtitle ? "mb-5" : ""
            }`}
          >
            {title}
          </h1>
          <h1 className=" text-2xl md:text-4xl lg:text-5xl drop-shadow font-bold mb-2">
            {subtitle}
          </h1>
          <span className="block max-w-[150px] md:max-w-[250px] h-[15px] bg-primary-light-color" />

          {description && (
            <p className=" text-sm lg:text-base drop-shadow font-medium mb-10">
              {description}
            </p>
          )}
          {buttonText && (
            <Button
              onClick={handleGoToPage}
              variant={`${
                buttonVariant === "secondary" ? "secondary" : "hero"
              }`}
            >
              {buttonText}
            </Button>
          )}
        </div>

        {iconPath && (
          <KTSVG path={iconPath} className={`${iconClassName} w-[40%]`} />
        )}
      </div>
    </div>
  );
}

export default CustomHero;
