import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { textBreakLine } from "../helpers/textParser";
import { toAbsoluteUrl } from "../helpers/assetHelper";

import CareerJobComponent from "../components/PagesComponents/CareerJobComponent";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import UploadCV from "../components/UploadCV";
import { SpinnerCircularFixed } from "spinners-react";

function Career() {
  const { t: translate } = useTranslation();

  function renderDescription(description) {
    return (
      <div>
        {textBreakLine(description, (parsed, idx) => (
          <p key={idx} className="mb-4">
            {parsed}
          </p>
        ))}
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <HeroComponent
        height={650}
        title={translate("hero_career_title")}
        description={renderDescription(translate("hero_career_description"))}
        backgroundImage={toAbsoluteUrl(
          "/assets/SSNTAssets/career-page-picture.jpg"
        )}
        wrapperClassName="bg-center"
      />

      <div className="md:max-w-xl lg:max-w-3xl mx-auto p-[15px] md:p-[20px] lg:p-[40px] flex flex-col justify-center items-center select-none">
        <h2 className="text-xl lg:text-2xl xl:text-3xl  text-primary-color font-bold mb-6">
          {translate("career_join_team_title")}
        </h2>
        <p className="text-black text-sm xl:text-base text-center font-medium mb-6">
          {translate("career_join_team_description")}
        </p>

        <UploadCV buttonText={translate("career_join_team_button")} />
      </div>
      {/* 
      {isLoadingJobs && (
        <div className="pb-[300px] pt-[55px] flex items-start justify-center ">
          <SpinnerCircularFixed
            size={100}
            thickness={157}
            speed={300}
            color="#44bb92"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      )}
      {!isLoadingJobs && (
        <div className="p-[15px] sm:p-[20px] lg:p-[40px] max-w-7xl mx-auto">
          <div className="bg-gray-50   flex flex-col justify-center items-center lg:justify-between rounded-xl p-2 sm:p-10 ">
            <div className="w-full flex flex-col justify-between">
              {jobsData.map((job) => {
                return <CareerJobComponent key={job.id} job={job} />;
              })}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default Career;
