import React, { useState } from "react";
import Button from "./Button";
import { useDispatch } from "react-redux";
import { fetchEmail } from "../redux/api/events.api";

function UploadCV({ buttonText, subject }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const MAX_SIZE_MB = 25;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    // Check if the selected file is a PDF
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= MAX_SIZE_BYTES) {
          setSelectedFile(file);
          setErrorMessage("");
        } else {
          setSelectedFile(null);
          setErrorMessage(
            `Te rog ataseaza un fiser PDF mai mic de ${MAX_SIZE_MB}MB!`
          );
        }
      } else {
        setSelectedFile(null);
        setErrorMessage("Te rog ataseaza un fiser PDF!");
      }
    }
  };

  const handleSendEmail = () => {
    // dispatch(
    //   fetchEmail({
    //     attachment: selectedFile,
    //     subject: subject,
    //   })
    // );
    setSelectedFile(null);
    setSuccessMessage("Fisierul a fost trimis cu succes!");
  };

  setTimeout(() => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }, 3000);

  return (
    <div className="flex flex-col items-center">
      <input
        type="file"
        id="uploadCV"
        accept="application/pdf"
        className="hidden"
        onChange={handleFileChange}
      />

      <label htmlFor="uploadCV" className="btn btn-primary">
        {buttonText}
      </label>

      {(errorMessage || successMessage) && (
        <p
          className={`${
            errorMessage ? "text-red-500" : "text-primary-color"
          } text-sm xl:text-base text-center font-semibold my-3`}
        >
          {errorMessage ? errorMessage : successMessage}
        </p>
      )}

      {selectedFile && (
        <>
          <p className="text-black text-sm xl:text-base text-center font-semibold my-6">
            Fisierul selectat:
            <span className="text-primary-color"> {selectedFile.name}</span>
          </p>
          <Button onClick={handleSendEmail}>Trimite</Button>
        </>
      )}
    </div>
  );
}

export default UploadCV;
