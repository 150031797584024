import React from "react";
import { useTranslation } from "react-i18next";

const cookiesPolicySections = [
  {
    title: "cookies_section_title_1",
    descriptions: [
      "cookies_section_description_1",
      "cookies_section_description_2",
      "cookies_section_description_3",
      "cookies_section_description_4",
    ],
  },
  {
    title: "cookies_section_title_2",
    subtitle: "cookies_section_subtitle_interpretation",
    description: "cookies_section_interpretation_description",
  },
  {
    subtitle: "cookies_section_subtitle_definitions",
    descriptions: [
      "cookies_section_definitions_description_1",
      "cookies_section_definitions_description_2",
      "cookies_section_definitions_description_3",
      "cookies_section_definitions_description_4",
    ],
    withMarginLeft: true,
  },
  {
    title: "cookies_section_title_3",
    subtitle: "cookies_section_subtitle_cookies_type",
    descriptions: [
      "cookies_section_cookies_type_description_1",
      "cookies_section_cookies_type_description_2",
    ],
  },
  {
    subtitle: "cookies_section_subtitle_essential_cookies",
    descriptions: [
      "cookies_section_essential_cookies_description_1",
      "cookies_section_essential_cookies_description_2",
      "cookies_section_essential_cookies_description_3",
    ],
    withMarginLeft: true,
  },
  {
    subtitle: "cookies_section_subtitle_functionality_cookies",
    descriptions: [
      "cookies_section_functionality_cookies_description_1",
      "cookies_section_functionality_cookies_description_2",
      "cookies_section_functionality_cookies_description_3",
    ],
    withMarginLeft: true,
  },
  {
    subtitle: "cookies_section_subtitle_regarding_cookies",
    descriptions: [
      "cookies_section_regarding_cookies_description_1",
      "cookies_section_regarding_cookies_description_2",
      "cookies_section_regarding_cookies_description_3",
    ],
  },
  {
    descriptions: [
      "cookies_section_regarding_cookies_description_4",
      "cookies_section_regarding_cookies_description_5",
      "cookies_section_regarding_cookies_description_6",
      "cookies_section_regarding_cookies_description_7",
    ],
    withMarginLeft: true,
  },

  {
    subtitle: "cookies_section_subtitle_contact_us",
    description: "cookies_section_contact_us_description",
  },
];

function CookiesPolicy() {
  const { t: translate } = useTranslation();
  return (
    <div className="max-w-[1300px] mx-auto pt-[55px] px-3 my-[55px]">
      <h1 className="max-w-7xl mx-auto text-4xl md:text-5xl lg:text-6xl drop-shadow mb-5 font-bold text-center">
        {translate("cookies_title")}
      </h1>

      <p>{translate("policy_description")}</p>

      <div className="py-5">
        {cookiesPolicySections.map((section, idx) => {
          return (
            <div key={`section-${idx}`} className="mb-4">
              {section.title && (
                <h1 className="text-3xl font-bold mb-2">
                  {translate(section.title)}
                </h1>
              )}

              {section.subtitle && (
                <h3
                  className={`${
                    section.withMarginLeft ? "ml-10" : ""
                  } text-xl font-semibold mb-2`}
                >
                  {translate(section.subtitle)}
                </h3>
              )}
              {section.description && (
                <p className="text-sm xl:text-base font-medium mb-6">
                  {translate(section.description)}
                </p>
              )}

              {section.descriptions &&
                section.descriptions.map((description, idx) => (
                  <p
                    key={`description-${idx}`}
                    className={`${
                      section.withMarginLeft ? "ml-10" : ""
                    } text-sm xl:text-base font-medium mb-6`}
                  >
                    {translate(description)}
                  </p>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CookiesPolicy;
