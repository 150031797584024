import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJobData } from "../../redux/reducer/events.reducer";

function CareerJobComponent({ wrapperClassName, job }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApplyToJob = (e) => {
    e.preventDefault();
    dispatch(setJobData(job));
    navigate(`/career/${job.id}`);
  };

  return (
    <div
      className={`${wrapperClassName} bg-white drop-shadow-sm p-4 mb-2 md:mb-4 `}
    >
      <div>
        <h3 className="text-primary-color text-xl font-semibold">{job.name}</h3>
        <p className="text-sm xl:text-base font-medium py-4">
          <span className="font-semibold">Descrierea jobului:</span>
          {job.description}
        </p>
      </div>

      <div className="flex flex-row justify-between">
        <div />
        <Button variant="primary" onClick={(e) => handleApplyToJob(e)}>
          APLICA
        </Button>
      </div>
    </div>
  );
}

export default CareerJobComponent;
