import React from "react";
import { IoClose } from "react-icons/io5";

function Input({
  value,
  id,
  type,
  labelName,
  onChange,
  deleteInputValue,
  inputClassName,
  labelClassName,
  disabled,
}) {
  return (
    <div className="relative mb-6 w-80">
      <input
        disabled={disabled}
        type={type ?? "text"}
        id={id}
        value={value}
        onChange={onChange}
        required
        className={`${inputClassName} block px-3 pb-2.5 pt-3 w-80 text-sm text-gray-900 bg-slate-100 rounded-md  border-primary-color appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-color outline-offset-0 outline-none focus:outline-primary-color focus:ring-0 focus:border-primary-color peer ease-in duration-200`}
        placeholder=" "
      />
      {type === "file" || id === "subject"
        ? ""
        : value.length > 0 && (
            <IoClose
              onClick={deleteInputValue}
              className="text-gray-500 absolute right-1 top-3 cursor-pointer w-[25px]"
            />
          )}
      {labelName ? (
        <label
          htmlFor={id}
          className={`${labelClassName} absolute text-sm text-gray-500 dark:text-gray-400 transform -translate-y-5 top-2 scale-75 outline-none  z-10 origin-[0] bg-slate-100 dark:bg-gray-900 px-3 peer-focus:px-3 peer-focus:text-primary-color peer-focus:dark:text-primary-color peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ease-in duration-200`}
        >
          {labelName}
        </label>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Input;
