export const clientConfig = {
  email: "office@ssnt.ro",
  primaryPhoneNumber: "+40 351 801 444",
  addressLocation: "Str. Aeroportului, Nr.195, Dolj, Romania 207206",

  orderNumber: "J16/25/2011",
  CUI: "RO27887894",

  department_email_1: "achizitii@2afarm.ro ",
  department_email_2: "juridic@2afarm.ro",
  department_email_3: "financiar@2afarm.ro",
  department_email_4: "logistic@2afarm.ro",

  video_url: "https://www.youtube.com/embed/x6qdIIJbUWQ",
};
