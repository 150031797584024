import React from "react";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { approachSoftwareConfig } from "../configs/approachSoftwareConfig";
import ApproachCustomSoftwareCard from "./ApproachCustomSoftwareCard";
import { useTranslation } from "react-i18next";

function ApproachCustomSoftwareComponent({ title }) {
  const { t: translate } = useTranslation();

  return (
    <div className="max-w-[1500px] mx-auto flex flex-col justify-center items-center  p-[20px] md:px-[30px] lg:py-[60px]">
      <h1 className="text-2xl md:text-4xl lg:text-5xl drop-shadow font-bold mb-2">
        {title}
      </h1>

      <div className="flex flex-wrap py-[40px]">
        {approachSoftwareConfig.map((software, idx) => {
          return (
            <div
              key={idx}
              className="flex flex-col md:basis-1/2 lg:basis-1/3 xl:basis-1/4 justify-start mb-5 md:mb-7 items-center my-3"
            >
              <ApproachCustomSoftwareCard
                title={translate(software.name)}
                description={translate(software.description)}
                icon={toAbsoluteUrl(software.icon)}
                buttonText={translate(software.buttonText)}
                buttonUrl={software.buttonUrl}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ApproachCustomSoftwareComponent;
