import React from "react";
import { toAbsoluteUrl } from "../../helpers/assetHelper";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function HalfBgWithText({
  height,
  image,
  buttonUrl,
  title,
  description,
  buttonText,
  wrapperClassName,
  contentClassName,
  buttonVariant,
  reverse,
}) {
  const navigate = useNavigate();
  const handleGoToPage = () => {
    navigate(buttonUrl);
  };

  return (
    <div
      className={` flex ${wrapperClassName}  ${
        reverse ? "flex-row-reverse" : "flex-col md:flex-row"
      }  `}
      style={{ height: height }}
    >
      <div
        className={`${contentClassName} flex flex-col justify-start md:w-1/2 text-white p-[30px] lg:py-[60px] lg:px-[30px] xl:py-[80px] xl:px-[140px]`}
      >
        <h3 className="text-xl lg:text-2xl xl:3xl font-bold mb-5">{title}</h3>

        <p className="mb-5">{description}</p>

        <Button
          onClick={handleGoToPage}
          className="!h-fit w-fit"
          variant={buttonVariant}
        >
          {buttonText}
        </Button>
      </div>

      <img
        src={toAbsoluteUrl(image)}
        alt={image}
        className="md:w-1/2 object-cover "
      />
    </div>
  );
}

export default HalfBgWithText;
