import React from "react";
import Content from "../Content";
import SliderWithText from "../SliderWithText";

function SliderAndContentComponent({
  title,
  description,
  descriptions,
  descriptionClass,
  buttonText,
  type,
  contentType,
  onClick,
  wrapperClassName,
  sliderData,
}) {
  return (
    <div
      className={`${wrapperClassName} p-[16px] sm:p-[40px] lg:p-[60px] flex flex-col justify-between items-center ${
        type === "reverse"
          ? "lg:items-center lg:flex-row-reverse"
          : "lg:items-center lg:flex-row"
      }  `}
    >
      <Content
        wrapperClassName={` w-11/12 lg:w-1/2 h-full ${
          type === "reverse" ? "" : "lg:mr-16"
        }`}
        contentType={contentType}
        title={title}
        description={description}
        descriptions={descriptions}
        buttonText={buttonText}
        onClick={onClick}
        descriptionClass={descriptionClass}
      />

      <SliderWithText
        sliderData={sliderData}
        className={`${type === "reverse" ? "lg:mr-16" : ""} z-10 relative
        before:content-['']
        before:absolute
        before:inset-0
        before:block
        before:bg-gradient-to-tr
        before:from-white
        before:to-white
        before:opacity-40
        before:z-[-5] w-full lg:w-1/2 h-[300px] md:h-[450px] mt-5 lg:mt-0 object-contain pointer-events-none`}
      />
    </div>
  );
}

export default SliderAndContentComponent;
