import React, { useEffect, useState } from "react";
import HeroComponent from "../components/PagesComponents/HeroComponent";

import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import useRoutesHelper from "../hooks/useRoutesHelper";

import Input from "../components/Input";

import { useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";
import UploadCV from "../components/UploadCV";
import { useTranslation } from "react-i18next";

const subject = "Angajare";

function CareerJobPage() {
  const { t: translate } = useTranslation();

  const { currentUrl } = useRoutesHelper();
  const navigate = useNavigate();

  const [currentJobPage, setCurrentJobPage] = useState("");

  const {
    events: {
      jobData: { data: currentJob, isLoading: isLoadingJobs },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("/");

    const getSlicedString = () => {
      if (paramsStartingIndex > 0) {
        return currentUrl.substring(paramsStartingIndex + 1);
      }
      return currentUrl;
    };

    setCurrentJobPage(getSlicedString());
  }, [currentUrl]);

  if (!currentJobPage) {
    return;
  }

  if (isLoadingJobs) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <SpinnerCircularFixed
          size={100}
          thickness={157}
          speed={300}
          color="#44bb92"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    );
  }

  if (!currentJob) {
    navigate("/*");
    return;
  }

  const { description, name: jobName } = currentJob;

  return (
    <div className="min-h-screen pt-[55px]">
      <HeroComponent
        height={450}
        title={jobName}
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0525.jpg")}
      />

      {currentJob && (
        <div className="max-w-7xl mx-auto p-[15px] sm:p-[20px] lg:p-[40px]">
          <div className="bg-gray-50 flex flex-col justify-center items-center rounded-xl p-2 sm:p-10 ">
            <p className="text-sm xl:text-base font-medium ">{description}</p>
          </div>
        </div>
      )}

      <div className="max-w-3xl mx-auto p-[15px] sm:p-[20px] lg:p-[40px]">
        <form className=" bg-gray-50  flex flex-col justify-center items-center rounded-xl p-2 sm:p-10">
          <h2 className="text-primary-color text-xl lg:text-2xl xl:text-3xl text-center font-bold mb-5 ">
            Esti interesat? Contacteaza-ne!
          </h2>

          <Input
            disabled
            labelName="Subiect"
            id="subject"
            value={`${subject}-${jobName}`}
          />

          <UploadCV
            buttonText={translate("career_join_team_button")}
            subject={jobName}
          />
        </form>
      </div>
    </div>
  );
}

export default CareerJobPage;
