import React from "react";
import OurProjectsComponent from "./OurProjectsComponent";
import KTSVG from "../../utils/KTSVG";
import { useTranslation } from "react-i18next";

function OurProjects({ projects }) {
  const { t: translate } = useTranslation();

  return (
    <div className="mx-4">
      {projects.map((project, idx) => {
        return (
          <OurProjectsComponent
            key={idx}
            projectName={project.name}
            projectDescription={translate(project.description)}
            projectImage1={project.image_1}
            projectImage2={project.image_2}
            projectImage3={project.image_3}
            projectTechnologies={project.technologies}
            sections={project.sections.map((section, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-col justify-center items-center px-[30px] py-[40px] basis-1/3"
                >
                  <div className="bg-hero-hover rounded-xl my-2">
                    <KTSVG
                      path={section.icon}
                      fill={section.iconColor}
                      svgClassName="w-[55px] h-[55px] p-1.5"
                    />
                  </div>

                  <h3 className="text-xl font-semibold text-white">
                    {translate(section.title)}
                  </h3>

                  <p className="text-gray-400 py-2 text-center font-medium">
                    {translate(section.description)}
                  </p>
                </div>
              );
            })}
          />
        );
      })}
    </div>
  );
}

export default OurProjects;
